import moment from "moment";

export const HEADERS = [
    {
        label: 'Action',
        value: 'action'
    },
    {
        label: 'Abbr',
        value: 'abbr'
    },
    {
        label: 'Firstname',
        value: 'firstName'
    },
    {
        label: 'Lastname',
        value: 'lastName'
    },
    {
        label: 'Work Email',
        value: 'docEmail'
    },
    {
        label: 'Personal Email',
        value: 'personalEmail'
    },
    {
        label: 'Cellphone',
        value: 'docMobile'
    },
    {
        label: 'Gender',
        value: 'gender'
    },
    {
        label: 'Dob',
        value: 'dateOfBirth',
        format: (data: string) => data ? moment(data).format('YYYY/MM/DD') : '-'
    },
    {
        label: 'Address',
        value: 'address'
    },
    {
        label: 'Home Location',
        value: 'homeLocation'
    },
    {
        label: 'Noti Pref',
        value: 'notificationPrefer'
    },
    {
        label: 'Can Work',
        value: 'canWork'
    },
    {
        label: 'Contract Start',
        value: 'contractStart',
        format: (data: string) => data ? moment(parseInt(data)).format('YYYY/MM/DD') : '-'
    },
    {
        label: 'Contract End',
        value: 'contractEnd',
        format: (data: string) => data ? moment(parseInt(data)).format('YYYY/MM/DD') : '-'
    },
    {
        label: 'Full/Part time',
        value: 'dentistWorkTimeType'
    },
    {
        label: 'Den Type',
        value: 'dentistType'
    },
    {
        label: 'Den Sub Type',
        value: 'dentistSubType'
    },
    {
        label: 'Den Shift Spec',
        value: 'dentistShiftSpecialty'
    },
    {
        label: 'Ukg Id',
        value: 'ukgid'
    },
    {
        label: 'ProdAdjWo %',
        value: 'prodAdjWo'
    },
    {
        label: 'Daily Rate Pay',
        value: 'dailyRatePay'
    },
    {
        label: 'Daily Rate Prod',
        value: 'dailyRateProd'
    },
    {
        label: 'Pay Over Base',
        value: 'payOverBase'
    },
    {
        label: 'First Start',
        value: 'firstStart'
    },
    {
        label: 'Monthly Base Pay',
        value: 'monthlyBasePay'
    },
    {
        label: 'Monthly Base Prod',
        value: 'monthlyBaseProduction'
    },
    {
        label: 'Monthly Base WDays',
        value: 'monthlyBaseWorkDays'
    },
    {
        label: 'Denovo Monthly Base Pay',
        value: 'denovoMonthlyBasePay'
    },
    {
        label: 'Denovo Monthly Work Days',
        value: 'denovoMonthlyWorkDays'
    },
    {
        label: 'payTimeOffDays',
        value: "payTimeOffDays"
    },
    {
        label: 'Loc Preference',
        value: 'locPrefer'
    },
    {
        label: 'Weekday Prefer',
        value: 'weekdayPrefer'
    },
    {
        label: 'Work History',
        value: 'workHistory',
    }
]

export const LOCKHISTORYHEADERS = [
    {
        label: 'Lock Date'
    },
    {
        label: 'Consult Price'
    },
    {
        label: 'Contract Name'
    },
    {
        label: 'Daily Rate Pay'
    },
    {
        label: 'Daily Rate Prod'
    },
    {
        label: 'Debond Bonus'
    },
    {
        label: 'DOT Bonus'
    },
    {
        label: 'Denovo Monthly Base Pay'
    },
    {
        label: 'Denovo Monthly Work Days'
    },
    {
        label: 'Monthly Base Pay'
    },
    {
        label: 'Monthly Base Production'
    },
    {
        label: 'Monthly Base Work Days'
    },
    {
        label: 'Otho Day Day Rate'
    },
    {
        label: 'Pay Over Base'
    },
    {
        label: 'Paid Time Off Days'
    },
    {
        label: 'Production Adjustment Work'
    },
    {
        label: 'SD Contract'
    },
    {
        label: 'SD Start Bonus'
    },
    {
        label: 'Start Bonus'
    },
    {
        label: 'Travel Stipend'
    }
]

export const WorkHistoryHeaders = [
    {
        label: 'Practice',
        value: 'practice'
    },
    {
        label: 'Days Worked',
        value: 'days'
    },
    {
        label: 'First Work Date',
        value: 'firstWorkDay',
        format: (data: string) => data ? moment(data).format('YYYY/MM/DD') : '-'
    },
    {
        label: 'Last Work Date',
        value: 'lastWorkDay',
        format: (data: string) => data ? moment(data).format('YYYY/MM/DD') : '-'
    },
    {
        label: 'Next Work Date',
        value: 'nextWorkDay',
        format: (data: string) => data !== "No Schedule" ? moment(data).format('YYYY/MM/DD') : data
    },
]



export const genderOptions = [
    { key: 'male', value: 'Male', text: 'Male' },
    { key: 'female', value: 'Female', text: 'Female' },
]

export const notificationPreferences = [
    { key: 'mail', value: 'Mail', text: 'Mail' },
    { key: 'text', value: 'Text', text: 'Text' },
]
export const weekdayPreference = [
    {key:"Monday", value:"Monday",text:"Monday"},
    {key:"Tuesday", value:"Tuesday",text:"Tuesday"},
    {key:"Wednesday", value:"Wednesday",text:"Wednesday"},
    {key:"Thursday", value:"Thursday",text:"Thursday"},
    {key:"Friday", value:"Friday",text:"Friday"},
    {key:"Saturday", value:"Saturday",text:"Saturday"}
  ];

export const udrPermissions = [
    { key: "Admin", value: "1",text:"Admin" },
    { key: "Dentist", value: "2",text:"Dentist" },
    { key: "Orthodontics", value: "3",text:"Orthodontics"},
    { key: "Oral Surgeon", value: "4",text:"Oral Surgeon" },
    { key: "Traveling Team", value: "5",text:"Traveling Team" },
    { key: "Credentialing", value: "6",text:"Credentialing" },
    { key: "View Only", value: "7",text:"View Only" },
    { key: "Hygienists", value: "9",text:"Hygienists" },
    { key: "Regional Manager", value: "8",text:"Regional Manager"},
    { key: "Provider Light", value: "10",text:"Provider Light" },
    { key: "SOFD", value: "11",text:"SOFD" },
    { key: "Ortho Manager", value: "15",text:"Ortho Manager"},
  ];

export const payTypeOptions = [
    { key: "W2", text: "W2", value: "W2" },
    { key: "1099", text: "1099", value: "1099" }
];

export const baseDentistTypeList = [
    {key:"General",text:"General", value:"General"},
    {key:"Ortho",text:"Ortho", value:"Ortho"},
    {key:"Oral Surgery",text:"Oral Surgery", value:"Oral Surgery"},
    {key: "Hygienists",text: "Hygienists", value: "Hygienists"}
  ];
  export const dentistSubTypeList = [
    {key:"Ortho",text:"Ortho", value:"Ortho"}
  ];

  export const dentistShiftSpecialityList = [
    {key:"Sedation",text:"Sedation", value:"Sedation"},
    {key:"GA",text:"GA",value:"GA"},
    {key: "TED",text:"TED",value: "TED"},
    {key: "Hygienists",text: "Hygienists", value: "Hygienists"}
  ];

  export const dentistWorkTimeType = [
    {text:"Part Time", value:"Part Time"},
    {text:"Full Time", value:"Full Time"}
  ];


  export const contractName=[
    {key:"Custom",text:"Custom", value:"Custom"},
    {key:"Greater Of",text:"Greater Of", value:"Greater Of"},
    {key:"Sum Of",text:"Sum Of", value:"Sum Of"},
    {key:"Hygienist Incentive",text:"Hygienist Incentive", value:"Hygienist Incentive"},
    {key:"UKG HYN",text:"UKG HYN", value:"UKG HYN"},
    {key: "GOX",text: "GOX", value: "GOX"},
    {text: "GOZ",key: "GOZ", value: "GOZ"},
    {text:"Collections",key:"Collections",value:"Collections"}
]

export const schedulePermissions = [
    {key:"Doctor",text:"Doctor", value:"Doctor"},
    {key:"Admin",text:"Admin", value:"Admin"},
    {key: "Hygienists",text: "Hygienists", value: "Hygienists"}
  ];